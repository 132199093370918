var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};
var site = site || {};

(function ($) {
  function _makeSwatch(skuId) {
    var sku = prodcat.data.getSku(skuId);
    var $swatchDiv = $();

    if (sku && sku.HEX_VALUE_STRING) {
      $swatchDiv = $('<div/>', {
        class: 'selectbox-swatch',
        style: 'background-color: ' + sku.HEX_VALUE_STRING + ';'
      });
    }

    return $swatchDiv;
  }

  function _setLabelSwatches($product, skuId) {
    var $label = $('.js-shade-select .selectBox-label', $product);

    $('.selectbox-swatch', $label).remove();
    $label.prepend(_makeSwatch(skuId));
  }

  function _showHideReplenishment($product, sku, $prodReplenishment) {
    // To filter only SPP product since we are getting Workswell with products too
    if ($product.hasClass('js-product-full') && $prodReplenishment.length > 0) {
      if (sku.REFILLABLE) {
        $prodReplenishment.show();
      } else {
        $prodReplenishment.hide();
      }
    }
  }
  // When the menu changes, trigger skuSelect:
  $(document).on('change', 'select.js-sku-menu', function () {
    var skuId = $(this).val();
    var sku = prodcat.data.getSku(skuId);
    var skuBaseId;

    if (sku) {
      skuBaseId = sku.SKU_BASE_ID;
      $(this).closest('.js-product').trigger('product.skuSelect', [skuBaseId]);
    }
  });

  // Likewise, whenever skuSelect is fired on a product, update the menu:
  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    $('select.js-sku-menu', this).val(skuBaseId);
    $('select.js-sku-menu.selectBox-attached', this).selectBox('value', skuBaseId);
    _setLabelSwatches($(this), skuBaseId);

    // Price update logic
    var sku = prodcat.data.getSku(skuBaseId);
    var $flag = $('.js_misc_flag_text', this);

    if (sku) {
      // Need to wrap sku inside of defaultSku because thats where the template reads
      var content = site.template.get({
        name: 'product_sku_price',
        data: { defaultSku: sku }
      });
      var prod = prodcat.data.getProduct(sku.PRODUCT_ID);

      $('.product-sku-price', this).html($(content).html());
      if (sku.MISC_FLAG_TEXT || prod.MISC_FLAG_TEXT) {
        $flag.html(sku.MISC_FLAG_TEXT || prod.MISC_FLAG_TEXT);
        $flag.removeClass('hidden');
      } else {
        $flag.addClass('hidden');
        $flag.html('');
      }

      // product replenishment
      var prodReplen = $(
        '.js-product-replenishment-toggle, a.js-replenishment-select, .js-product-full__replenishment-qm'
      );

      _showHideReplenishment($(this), sku, prodReplen);
    }
  });

  $(document).on('product.init', '.js-product', function () {
    var $shadeSelect = $('select.js-sku-menu.js-shade-select.selectBox-attached', this);

    if (!$shadeSelect.length) {
      return;
    }

    // There can be multiple instances in the same product template (eg. mobile
    // vs pc display logic)
    $shadeSelect.each(function () {
      var $selectControl = $($(this).selectBox('control')); // extra $() for iPad
      var $options = $selectControl.data('selectBox-options');

      if (!_.isUndefined($options) && !_.isNull($options)) {
        var $links = $options.find('li a');

        // Remove any existing swatches before adding them:
        $('.selectbox-swatch', $options).remove();
        // add shade divs to custom select
        $links.each(function () {
          var skuId = $(this).attr('rel');
          var $swatch = _makeSwatch(skuId);

          $(this).prepend($swatch);
        });
      }
    });

    _setLabelSwatches($(this), $(this).data('sku-base-id'));
  });
})(jQuery);
